<template>
  <div id="base-home createSearch">
    <v-container>
      <h1 class="title1" style="text-align: left;color: #F69946;">Contact Us</h1>

      <v-row>
        <v-col cols="12" md="12" sm="12">
          <div class="subTitle1" style="text-align: left">
            Our Office:
          </div>

          <v-list two-line>
            <template>
              <v-list-item>
                <v-list-item-action>
                  <v-btn fab style="color: green">
                    <v-icon>mdi-map-marker-radius</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-subtitle>#1036 Road5, Sangkat Mittapheap, Khan Russey Keo, Phnom Penh, Cambodia</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-list two-line>
            <template>
              <v-list-item>
                <v-list-item-action>
                  <v-btn fab style="color: green">
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Mail Us</v-list-item-title>
                  <v-list-item-subtitle>info@bayonapp.com</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-list two-line>
            <template>
              <v-list-item>
                <v-list-item-action>
                  <v-btn fab style="color: green">
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Contact Us</v-list-item-title>
                  <v-list-item-subtitle>(+855) 010 262715 </v-list-item-subtitle>
                  <v-list-item-subtitle>(+855) 099 262715</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-col>

      </v-row>

    </v-container>

  </div>

</template>


<script>
export default {
  name: "contact",
  mounted() {
    this.$jQuery("body").off();
    this.onResize()
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  data(){
    return{
      videoLink:"",
      videoEmbedCode:"",
      windowSize: {
        x:0,
        y:0
      }
    }
  },

  watch: {
    "videoLink"(val) {
      let vm=this;
      vm.videoEmbedCode = "";
      if (val && val.split('youtu.be').length === 2) {
        vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split('/watch?v=').length > 0) {
        vm.videoEmbedCode = "https://www.youtube.com/embed/" + val.split('/watch?v=')[1].split("&list=")[0].split('&t=')[0];
      }
    },
  },
  methods:{
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    }
  },
  created() {
  }

};
</script>


<style>

.title1 {
  color: teal;
}

.title2 {
  color: teal;
}

.subTitle1 {
  size: 12px;
  text-align: justify !important;
}

.subTitle2 {
  size: 12px;
  text-align: justify !important;

}
</style>
